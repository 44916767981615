import "./HomeMain.css";
import LHeaderMain from "./LeftHeader/LeftHeaderMain";
import RHeaderMain from "./RightHeader/RightHeaderMain";

function HomeMain()
{
    return (
        <div className="HomeMain">
            <LHeaderMain/>
            <RHeaderMain/>
        </div>
    );
}

export default HomeMain