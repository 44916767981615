import "./FiguresMain.css"
import Figure from "./Figure"

function Figures()
{
    return (
        <div className="figures-container">
            <Figure strength="140" description="EXPERT COACHES"/>
            <Figure strength="978" description="MEMBERS"/>
            <Figure strength="104" description="FITNESS PROGRAMS"/>
        </div>
    );
}

export default Figures
