import "./Hero-text.css"

function Herotext()
{
    return (
        <div className="herotext-container">
            <div className="herotext" id="shapeyour_id">
                <span className="herotext-font-border">SHAPE</span>
                <span className="herotext-normalfont">YOUR</span>
            </div>
            <div id="herotext-ibody" className="herotext">IDEAL BODY</div>
            <div className="herotext-description">Here we will help you to shape and build your ideal body and live up your life to fullest
            </div>
        </div>
    )
}

export default Herotext