import "./PlansMain.css"
import Plan from "./Plan";

function PlansMain()
{
    return (
        <div className="Plans-container">
            <Plan title="Basic" amount="999" planDesc1="18 Sessions /month" planDesc2="Free consultaion from coaches" planDesc3="Access to The Community" />
            <Plan title="Premium" amount="1499" planDesc1="25 Sessions /month" planDesc2="Coach & Fitness Merchandises" planDesc3="Access to Minibar" />
            <Plan title="Couples" amount="2199" planDesc1="25 Sessions /month" planDesc2="Consultaion of Private Coach" planDesc3="Free Fitness Merchandises" />
        </div>
    );
}

export default PlansMain