import "./LeftHeaderMain.css"
import Navbar from "./Navbar";
import Bestad from "./Best-ad";
import Herotext from "./Hero-text";
import Figures from "./FiguresMain/FiguresMain"


function leftHeaderMain()
{
    return (
        <div className="leftHeaderMain">
           <Navbar/> 
           <Bestad/>
           <div className="blurlh"></div>
           <Herotext/>
           <Figures/>
           <div id="blurlhbottom"></div>
        </div>
    );
}

export default leftHeaderMain 