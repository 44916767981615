import "./ReasonsMain.css"
import Img1 from "./img-1.png"
import Img2 from "./img-2.png"
import Img3 from "./img-3.png"
import Img4 from "./img-4.png"
import Ricon from "./reason-icon.png"
import Ptimg1 from "./partnerimg-1.png"
import Ptimg2 from "./partnerimg-2.png"
import Ptimg3 from "./partnerimg-3.png"

function ReasonsMain()
{
    return(
        <div className="reason-container">
            <div className="reason-grid">
                <div className="gridchild gridchild-1">
                    <img src={Img1} alt="imggrid" id="grid-img1" />
                </div>
                <div className="gridchild gridchild-2">
                    <img src={Img2} alt="imggrid" id="grid-img2" />
                </div>
                <div className="gridchild gridchild-3">
                    <img src={Img3} alt="imggrid" id="grid-img3" />
                </div>
                <div className="gridchild gridchild-4">
                    <img src={Img4} alt="imggrid" id="grid-img4" />
                </div>
            </div>
            <div className="reason-description">
                <h3 className="reason-description-h3">SOME REASONS</h3>
                <span className="reason-description-h1-ts">WHY</span>
                <span className="reason-description-h1">CHOOSE US?</span>
                <div className="reason-description-div">
                    <img className="reason-description-div-img" src={Ricon} alt="rimg"/>
                    <p className="reason-description-div-p">OVER 140+ EXPERT COACHS</p>
                </div>
                <div className="reason-description-div">
                    <img className="reason-description-div-img" src={Ricon} alt="rimg"/>
                    <p className="reason-description-div-p">TRAIN SMARTER AND FASTER THAN BEFORE</p>
                </div>
                <div className="reason-description-div">
                    <img className="reason-description-div-img" src={Ricon} alt="rimg"/>
                    <p className="reason-description-div-p">1 FREE PROGRAM FOR NEW MEMBER</p>
                </div>
                <div className="reason-description-div">
                    <img className="reason-description-div-img" src={Ricon} alt="rimg"/>
                    <p className="reason-description-div-p">RELIABLE PARTNERS</p>
                </div>
                <div className="partners">
                    <h2 className="partners-h2">OUR PARTNERS</h2>
                    <div className="partnersimg-container">
                        <img className="partner-image" src={Ptimg1} alt="ptimg" />
                        <img className="partner-image" src={Ptimg2} alt="ptimg" />
                        <img className="partner-image" src={Ptimg3} alt="ptimg" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReasonsMain