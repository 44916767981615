import "./Plan.css"
import Planicon from "./plan-icon.png"

function Plan(props)
{
    return(
        <div className="plancontainer">
            <h3 className="plantitle">{props.title} Plan</h3>
            <h1 className="planamount"><i class="fa-solid fa-indian-rupee-sign planamount-icon"></i> {props.amount}</h1>
            <div className="plandesc">
                <img className="tickicon" src={Planicon}  alt="Planicon"/>
                <p className="plandesc-p" >{props.planDesc1}</p>
            </div>
            <div className="plandesc">
                <img className="tickicon" src={Planicon}  alt="Planicon"/>
                <p className="plandesc-p">{props.planDesc2}</p>
            </div>
            <div className="plandesc">
                <img className="tickicon" src={Planicon}  alt="Planicon"/>
                <p className="plandesc-p">{props.planDesc3}</p>
            </div>
            <p className="benifitContent">See More Benefits >></p>
            <button className="planSubmit">Join Now</button>
        </div>
        
    );
}

export default Plan