import "./ProgramsMain.css"
import Program from "./Program"

function Programs()
{
    return (
        <div className="Programs-container">
            <div id="heading">EXPLORE OUR PROGRAMS TO SHAPE YOU</div>
            <div id="program-menu">
                <div className="Program">
                    <i class="fa-sharp fa-solid fa-dumbbell program-icon"></i>
                    <Program title="Strength Training" description="In this program, you are trained to improve your strength through many exercises." />
                </div>
                <div className="Program">
                    <i class="fa-solid fa-person-running program-icon"></i>
                    <Program title="Cardio Training" description="In this program, you are trained to do sequential moves in range of 20 until 30 minutes." />
                </div>
                <div className="Program">
                    <i class="fa-solid fa-fire-flame-curved program-icon"></i>
                    <Program title="Fat Burning" description="This program is suitable for you who wants to get rid of your fat and lose their weight." />
                </div>
                <div className="Program">
                    <i class="fa-solid fa-heart-circle-bolt program-icon"></i>
                    <Program title="Health Fitness" description="This programs is designed for those who exercises only for their body fitness not body building." />
                </div>
            </div>
            <div className="blurpg"></div>
        </div>
    );
}

export default Programs