import "./App.css"

import HomeMain from "./Components/Home/HomeMain"
import Programs from "./Components/Programs/ProgramsMain";
import ReasonsMain from "./Components/Reasons/ReasonsMain";
import PlansMain from "./Components/Plans/PlansMain";
import JoinusMain from "./Components/Join us/JoinusMain";
import Footer from "./Components/Footer/Footermain";

function App()
{
    return (
        <div className="App">
            <HomeMain/>
            <Programs/>
            <ReasonsMain/>
            <PlansMain/>
            <JoinusMain/>
            <Footer/>
        </div>
    );
    
}

export  {App}