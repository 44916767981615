import "./Navbar.css"
import logo from "./logo.png"
import { useState } from "react"

function Navbar()
{
    const [showmediaicons , setshowmediaicons] = useState(false);
    return (
        <div className="navbarcontainer">
            <div className="logo_container">
                <img id="logo_container_img" src={logo} alt="logo" />
            </div>
            <div id="Rbtn" onClick={() => {
                setshowmediaicons(!showmediaicons);
            }}>
                    <div className="Rbtnchild"></div>
                    <div className="Rbtnchild"></div>
                    <div className="Rbtnchild"></div>
                </div>
                <div className= {showmediaicons ? "nav-buttonsstyle" : "navbar_menu"}>
                <a className="navbar_menu_items" href="https://chisel.co.in/fitness-centres/gurgaon/">Home</a>
                <a className="navbar_menu_items" href="https://chisel.co.in/fitness-centres/gurgaon/">Programs</a>
                <a className="navbar_menu_items" href="https://chisel.co.in/fitness-centres/gurgaon/">Why us</a>
                <a className="navbar_menu_items" href="https://chisel.co.in/fitness-centres/gurgaon/">Plans</a>
                <a className="navbar_menu_items" href="https://chisel.co.in/fitness-centres/gurgaon/">Testimonials</a>
            </div>
        </div>
    );
}

export default Navbar