import "./RightHeaderMain.css"
import Bgimg from "./bg-img.png"
import Heart from "./heart.png"
import Heroimg from "./hero-image.png"

function rightHeaderMain()
{
    return (
        <div className="rightHeaderMain">
            <div className="rightHeaderMain-button-container">
                <button className="rightHeaderMain-button">Join Now</button>
            </div>
            <div className="heartcard-container">
                <div className="heartcard">
                    <img id="heartimage" src={Heart} alt="heartrate" />
                    <div className="heading">Heart Rate</div>
                    <div className="heartrate">116 bpm</div>
                </div>
            </div>
            <img id="bgimg" src={Bgimg} alt="BGIMG"/>
            <img id="heroimg" src={Heroimg} alt="HeroImage"/>
            
        </div>
    );
}

export default rightHeaderMain