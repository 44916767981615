import "./Figure.css"

function Figure(props)
{
    const strength= props.strength
    const description=props.description

    return(
        <div className="Figure-container">
            <div className="Figure-child1">
                <span id="Figure-child11">+</span>
                <div id="Figure-child12">{strength}</div>
            </div>
            <div className="Figure-child2">{description}</div>
        </div>
    )
}

export default Figure