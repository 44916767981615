import "./Best-ad.css";

function Bestad()
{
    return(
        <div className="best-ad">
            <div className="bestad-capsule"></div>
            <span className="bestad-content">The Best Fitness Club IN the Town</span>
        </div>
    )
}

export default Bestad