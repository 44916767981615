import "./Program.css"

function Program(props)
{
    return (
        <div className="programs-container">
            <div className="programs-container-child1">
                <h1 className="programs-container-child1-h1">{props.title}</h1>
                <p className="programs-container-child1-p">{props.description}</p>
            </div>
            <div className="programs-container-child2">
                <button className="programs-container-child2-button">Join Now</button>
            </div>
        </div>

    );
}

export default Program