import "./JoinusMain.css"

function JoinusMain()
{
    return (
        <div className="joinus-container">
            <div className="blurjs"></div>
            <div className="joinus-content">
                <span className="txt-stroke" id="ready">READY</span>
                <span className="txt-stroke">TO</span>
                <span className="txt-solid">LEVEL UP<br/>YOUR BODY</span>
                <span className="txt-stroke">WITH US?</span>
            </div>
            <div className="joinus-email">
                <input className="email-input" type="email" placeholder="Enter your Email Address here" ></input>
                <button className="email-submit">Join Now</button>
            </div>
        </div>
    )
}

export default JoinusMain