import "./Footermain.css"
import Iconimg1 from "./iconimg-1.png"
import Iconimg2 from "./iconimg-2.png"
import Iconimg3 from "./iconimg-3.png"
import Flogo from "./footerlogo.png"

function Footer()
{
    return (
        <div className="footer-container">
            <div className="blur"></div>
            <div className="footer-icon-parent">
                <img className="footericon" src={Iconimg1} alt="icimg" />
                <img className="footericon" src={Iconimg2} alt="icimg" />
                <img className="footericon" src={Iconimg3} alt="icimg" />
            </div>
            <div className="footer-logo">
                <img className="footer-logo-child" src={Flogo} alt="icimg" />
            </div>
        </div>
    )
}

export default Footer